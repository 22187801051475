import React, {useEffect} from "react";
import Precios from './Precios';
const PreciosLink = () => {
useEffect( ()=>{ 

		var my_awesome_script = document.createElement('script');

		my_awesome_script.setAttribute('src','/style/js/scripts.js');

		document.head.appendChild(my_awesome_script);

	});
	return(
		<Precios/>
	);
 
};
export default PreciosLink;