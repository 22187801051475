import * as firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyBE7qF7rfZbSMH0_LGy4Rwq8P1cvSZ-3fc",
    authDomain: "encuestas-io.firebaseapp.com",
    databaseURL: "https://encuestas-io.firebaseio.com",
    projectId: "encuestas-io",
    storageBucket: "encuestas-io.appspot.com",
    messagingSenderId: "567789876017",
    appId: "1:567789876017:web:d4483c4f86491fb610f610",
    measurementId: "G-RMS097YJFZ"
  };
  
const firebaseApp = firebase.initializeApp(firebaseConfig);	
const db = firebaseApp.firestore();


export default db;