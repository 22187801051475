import React from "react";
import { Link } from "react-router-dom";
const Header = () => (
   
<nav className="navbar absolute transparent navbar-expand-lg">
      <div className="container">
        <div className="navbar-brand"><a href="index.html"><img src="#" srcSet="/style/images/logo.png" alt="" style={{width:200+'px'}} /></a></div>
        <div className="navbar-other ml-auto order-lg-3">
          <ul className="navbar-nav flex-row align-items-center" data-sm-skip="true">
            <li className="nav-item">
              <div className="navbar-hamburger d-lg-none d-xl-none ml-auto"><button className="hamburger animate plain" data-toggle="offcanvas-nav"><span></span></button></div>
            </li>
           
            <li className="nav-item"><button className="plain" data-toggle="offcanvas-info"></button></li>
          </ul>
         
        </div>
        
        <div className="navbar-collapse offcanvas-nav">
          <div className="offcanvas-header d-lg-none d-xl-none">
            <Link to="/"><img src="#" srcSet="/style/images/logo.png 1x, style/images/logo@2x.png 2x" alt="" /></Link>
            <button className="plain offcanvas-close offcanvas-nav-close"><i className="jam jam-close"></i></button>
          </div>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item"><Link className="nav-link" to="/">Inicio</Link></li>
				<li className="nav-item"><Link className="nav-link" to="/caracteristicas">Cómo funciona?</Link></li>
				<li className="nav-item"><Link className="nav-link" to="/plantillas">Plantillas o modelos</Link></li>
				<li className="nav-item"><Link className="nav-link" to="/precios">Precios</Link></li>
				<li className="nav-item"><Link className="nav-link" to="/blog">Blog</Link></li>
				<li className="nav-item"><Link className="nav-link" to="/contacto">Contacto</Link></li>
				<li className="nav-item"><a className="nav-link" href="https://dashboard.encuestas.io">Acceder <i className="icofont-sign-in"></i></a></li>
			</ul>
         
        </div>
       
      </div>
     
    </nav>


);
export default Header;