import React from "react";

const Caracteristicas = () => {
	 
	return(
	<div className="wrapper light-wrapper">
      <div className="container inner">
	      
        <div className="text-center">
          <h2 className="title-color color-gray">¿C&oacute;mo funciona encuestas.io?</h2>
          
        </div>
        
        <div className="space40"></div>
        <ul className="nav nav-tabs nav-tabs-centered nav-tabs-bg d-flex justify-content-between nav-justified text-center">
          <li className="nav-item"> <a className="nav-link active" data-toggle="tab" href="#tab4-1">
              <div className="icon icon-svg mb-10"><img src="/style/images/icons/FACIL.png" alt=""/></div><span>Fácil</span>
            </a> </li>
          <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#tab4-2">
              <div className="icon icon-svg mb-10"><img src="/style/images/icons/PERSONALIZABLE.png" alt=""/></div><span>Personalizable</span>
            </a> </li>
          <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#tab4-3">
              <div className="icon icon-svg mb-10"><img src="/style/images/icons/DISTRIBUCION.png" alt=""/></div><span>De fácil distribución</span>
            </a> </li>
          <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#tab4-4">
              <div className="icon icon-svg mb-10"><img src="/style/images/icons/DISPOSITIVOS-05.png" alt="" /></div><span>Multidispositivo</span>
            </a> </li>
			
			<li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#tab4-5">
              <div className="icon icon-svg mb-10"><img src="/style/images/icons/SEGURIDAD.png" alt="" /></div><span>Seguro</span>
            </a> </li>
			
			<li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#tab4-6">
              <div className="icon icon-svg mb-10"><img src="/style/images/icons/TIEMPO REAL.png" alt="" /></div><span>En tiempo real</span>
            </a> </li>
			
        </ul>
        
        <div className="space60 d-none d-md-block"></div>
        <div className="tab-content">
          <div className="tab-pane show active" id="tab4-1">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="pr-15 pr-md-0 pr-sm-0 h-100">
                  <figure>
                    <img src="style/images/art/ui1.png" alt="" />
                  </figure>
                </div>
              </div>
              
              <div className="space30 d-none d-md-block d-lg-none"></div>
              <div className="col-lg-5">
                <div className="pl-15 pl-md-0 pl-sm-0 h-100">
                  <h3>Fácil</h3>
                  <p>Sin instalaciones ni descargas, todo online. Interfaz intuitiva para crear desde la
encuesta más básica hasta la más sofisticada y profesional.</p>
                  
                </div>
              </div>
              
            </div>
            
          </div>
          <div className="tab-pane" id="tab4-2">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="pr-15 pr-md-0 pr-sm-0 h-100">
                  <figure>
                    <img src="style/images/art/ui2.png" alt="" />
                  </figure>
                </div>
              </div>
              
              <div className="space30 d-none d-md-block d-lg-none"></div>
              <div className="col-lg-5">
                <div className="pl-15 pl-md-0 pl-sm-0 h-100">
                  <h3>Personalizable</h3>
                  <p>Añade logotipo, tipografías y colores corporativos, imágenes o vídeos. Personaliza la
URL con tu propio dominio.</p>
                 
                </div>
              </div>
              
            </div>
            
          </div>
          <div className="tab-pane" id="tab4-3">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="pl-15 pr-md-0 pl-sm-0 h-100">
                  <h3>De fácil distribución</h3>
                  <p>Envía invitaciones a la encuesta desde tu email, WhatsApp o desde nuestra
plataforma de envíos, comparte en redes o publica en tu web.</p>
                 
                  <div className="space10"></div>
                  <a href="#" className="btn btn-default">More Details</a>
                </div>
              </div>
              
              <div className="space30 d-none d-md-block d-lg-none"></div>
              <div className="col-lg-5">
                <div className="pr-15 pl-md-0 pr-sm-0 h-100">
                  <figure>
                    <img src="style/images/art/ui3.png" alt="" />
                  </figure>
                </div>
              </div>
              
            </div>
            
          </div>
          <div className="tab-pane" id="tab4-4">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="pr-15 pr-md-0 pr-sm-0 h-100">
                  <figure>
                    <img src="style/images/art/ui4.png" alt="" />
                  </figure>
                </div>
              </div>
              
              <div className="space30 d-none d-md-block d-lg-none"></div>
              <div className="col-lg-6">
                <div className="pl-15 pl-md-0 pl-sm-0 h-100">
                  <h3>Multidispositivo</h3>
                  <p>Cuestionarios que se adaptan a cualquier formato de pantalla. Lo que hace que tu
encuesta pueda ser vista en cualquier plataforma o dispositivo</p>
                   
                </div>
              </div>
              
            </div>
            
          </div>
		  
		  <div className="tab-pane" id="tab4-5">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="pr-15 pr-md-0 pr-sm-0 h-100">
                  <figure>
                    <img src="style/images/art/ui4.png" alt="" />
                  </figure>
                </div>
              </div>
              
              <div className="space30 d-none d-md-block d-lg-none"></div>
              <div className="col-lg-6">
                <div className="pl-15 pl-md-0 pl-sm-0 h-100">
                  <h3>Seguro</h3>
                  <p>Alojado en Servidores Web Services (CA), con uno de los entornos más seguros del
planeta.</p>
                  
                </div>
              </div>
              
            </div>
            
          </div>
		  
		  
		  <div className="tab-pane" id="tab4-6">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="pr-15 pr-md-0 pr-sm-0 h-100">
                  <figure>
                    <img src="style/images/art/ui4.png" alt="" />
                  </figure>
                </div>
              </div>
              
              <div className="space30 d-none d-md-block d-lg-none"></div>
              <div className="col-lg-6">
                <div className="pl-15 pl-md-0 pl-sm-0 h-100">
                  <h3>En tiempo real</h3>
                  <p>Visualiza los resultados de las encuestas en tiempo real. Descarga en cualquier
momento informes Excel, PNG o PDF.</p>
                  
                </div>
              </div>
              
            </div>
            
          </div>
        </div>
        
        <div className="space160"></div>
	</div>	</div>	
	);
 
};
export default Caracteristicas;