import React, { useState,useEffect } from "react";
import { Link } from 'react-router-dom';
import db from './firebase';


const Blog = () => {

	
	const [post,setPost] = useState([]);
	
	useEffect( ()=>{
			
					db.collection("posts")
					.get()
					.then(querySnapshot => {
						
					  const data = querySnapshot.docs.map(doc =>({

						  id:doc.id,
						  ...doc.data(),
						  
					  }));
					  
					  //console.log(data); 
					  
					  setPost(data);
					  
					});
		
	});
	
	return(
	<div className="wrapper light-wrapper">
		<div className="container inner">
			<div className="blog grid grid-view">
				<div className="container p-4"><h1>Blog - Pronto</h1></div>
					<div className="blog grid grid-view">
						<div className="row isotope">
						
								{ post.map( i => 

								<div key={i.id} className="item post grid-sizer col-md-6 col-lg-4">
											  <figure className="overlay overlay1 rounded mb-30">
											  
											  <Link to={{pathname: `/blog/${i.slug}`}}>

											  <img src="/style/images/art/b1.jpg" alt="" /></Link>
												<figcaption>
												  <h5 className="from-top mb-0">Leer más</h5>
												</figcaption>
											  </figure>
											  
											  <h2 className="post-title">
											  
											  <Link to={{pathname: `/blog/${i.slug}`}}>
											  
											  {i.title}
											  
											  </Link>
											  
											  </h2>
											  
											  <div className="post-content">
												<p>{i.lead}</p>
											  </div>
											   
									</div>
								
								) 
								}
						
						
					</div>
			</div>
			</div>
		</div>
	</div>

	);
 
};
export default Blog;