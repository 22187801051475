import React from 'react';


const Precios = () => {

return (
 <div className="wrapper light-wrapper">
      <div className="container inner">
        <h2 className="title-color color-gray text-center">Nuestros </h2>
        <h3 className="display-3 text-center">Precios</h3>
        <div className="space40"></div>
        <div className="pricing-wrapper">
          <div className="pricing-switcher-wrapper switcher">
            <div className="pricing-switchers">
              <div className="pricing-switcher pricing-switcher-active">Mensual</div>
              <div className="pricing-switcher">Anual</div>
              <div className="switcher-button bg-default"></div>
            </div>
          </div>
          <div className="space60"></div>
          <div className="row no-gutters">
            <div className="col-md-4">
              <div className="pricing panel box bg-white shadow">
                <div className="panel-heading">
                  <div className="icon icon-svg mb-10"><img src="style/images/icons/ec-mobile.png" alt="" /></div>
                  <h4 className="panel-title mb-35">Plan B&aacute;sico</h4>
                  <div className="prices color-dark">
                    <div className="price price-show"><span className="price-currency">$</span><span className="price-value">14.99</span> <span className="price-duration">Mensual</span></div>
                    <div className="price price-hide price-hidden"><span className="price-currency">$</span><span className="price-value">12.50</span> <span className="price-duration">Mensual</span></div>
                  </div>
                </div>
                
                <div className="panel-body">
                  <table className="table">
                    <tr>
                      <td><strong>Ilimitadas</strong> Cantidad de encuestas </td>
                    </tr>
                    <tr>
                      <td><strong>Ilimitadas</strong> Preguntas por encuesta </td>
                    </tr>
                    <tr>
                      <td><strong>Ilimitadas</strong> Respuestas por encuesta </td>
                    </tr>
                    <tr>
                      <td><strong>EMAIL</strong> Soporte</td>
                    </tr>
                    <tr>
                      <td><strong>No</strong> Pie de página personalizable</td>
                    </tr>
					<tr>
                      <td><strong>No</strong> Recompensas tipo Sorteo</td>
                    </tr>
					<tr>
                      <td><strong>No</strong> Correos masivos</td>
                    </tr>
                   
                  </table>
                </div>
               
                <div className="panel-footer"> <a href="#" className="btn" role="button">Seleccionar</a></div>
              </div>
              
            </div>
           
            <div className="col-md-4 popular">
              <div className="pricing panel box bg-white shadow">
                <div className="panel-heading">
                  <div className="icon icon-svg mb-10"><img src="style/images/icons/ec-online-shop.png" alt="" /></div>
                  <h4 className="panel-title mb-35">Plan medio</h4>
                  <div className="prices color-dark">
                    <div className="price price-show"><span className="price-currency">$</span><span className="price-value">24.99</span> <span className="price-duration">Mensual</span></div>
                    <div className="price price-hide price-hidden"><span className="price-currency">$</span><span className="price-value">20.99</span> <span className="price-duration">Mensual</span></div>
                  </div>
                </div>
                
                <div className="panel-body">
                  <table className="table">
                    <tr>
                      <td><strong>Ilimitadas</strong> Cantidad de encuestas </td>
                    </tr>
                    <tr>
                      <td><strong>Ilimitadas</strong> Preguntas por encuesta </td>
                    </tr>
                    <tr>
                      <td><strong>Ilimitadas</strong> Respuestas por encuesta </td>
                    </tr>
                    <tr>
                      <td><strong>Email, Whatsapp</strong> Soporte</td>
                    </tr>
                    <tr>
                      <td><strong>Sí</strong> Pie de página personalizable</td>
                    </tr>
					<tr>
                      <td><strong>Sí</strong> Recompensas tipo Sorteo</td>
                    </tr>
					<tr>
                      <td><strong>Sí</strong> Correos masivos <span>50 por hora</span></td>
                    </tr>
                  </table>
                </div>
               
                <div className="panel-footer"> <a href="#" className="btn" role="button">Seleccionar</a></div>
              </div>
             
            </div>
           
            <div className="col-md-4">
              <div className="pricing panel box bg-white shadow">
                <div className="panel-heading">
                  <div className="icon icon-svg mb-10"><img src="style/images/icons/ec-store.png" alt="" /></div>
                  <h4 className="panel-title mb-35">Plan Avanzado</h4>
                  <div className="prices color-dark">
                    <div className="price price-show"><span className="price-currency">$</span><span className="price-value">34.99</span> <span className="price-duration">Mensual</span></div>
                    <div className="price price-hide price-hidden"><span className="price-currency">$</span><span className="price-value">29.99</span> <span className="price-duration">Mensual</span></div>
                  </div>
                </div>
               
                <div className="panel-body">
                  <table className="table">
                    <tr>
                      <td><strong>Ilimitadas</strong> Cantidad de encuestas </td>
                    </tr>
                    <tr>
                      <td><strong>Ilimitadas</strong> Preguntas por encuesta </td>
                    </tr>
                    <tr>
                      <td><strong>Ilimitadas</strong> Respuestas por encuesta </td>
                    </tr>
                    <tr>
                      <td><strong>Email, Whatsapp</strong> Soporte</td>
                    </tr>
                    <tr>
                      <td><strong>Sí</strong> Pie de página personalizable</td>
                    </tr>
					<tr>
                      <td><strong>Sí</strong> Recompensas tipo Sorteo</td>
                    </tr>
					<tr>
                      <td><strong>Sí</strong> Correos masivos <span>100 por hora</span></td>
                    </tr>
                  </table>
                </div>
                
                <div className="panel-footer"> <a href="#" className="btn" role="button">Seleccionar</a></div>
              </div>
              
            </div>
            
          </div>
          
        </div>
        
        <div className="space140"></div>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="basic-slider owl-carousel gap-small text-center" data-margin="30">
              <div className="item">
                <blockquote className="icon larger">
                  <p>"Sed posuere consectetur est at lobortis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis, est non commodo luctus, nisi erat."</p>
                  <div className="blockquote-details justify-content-center">
                    <div className="img-blob blob1"><img src="style/images/art/t5.jpg" alt="" /></div>
                    <div className="info">
                      <h6 className="mb-0">Cornelia Gibson</h6>
                      <span className="meta mb-0">Financial Analyst</span>
                    </div>
                  </div>
                </blockquote>
              </div>
              
              <div className="item">
                <blockquote className="icon larger">
                  <p>"Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod semper. Cras justo odio, dapibus."</p>
                  <div className="blockquote-details justify-content-center">
                    <div className="img-blob blob1"><img src="style/images/art/t2.jpg" alt="" /></div>
                    <div className="info">
                      <h6 className="mb-0">Coriss Ambady</h6>
                      <span className="meta mb-0">Marketing Specialist</span>
                    </div>
                  </div>
                </blockquote>
              </div>
              
              <div className="item">
                <blockquote className="icon larger">
                  <p>"Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cras justo odio, dapibus ac facilisis in, egestas eget."</p>
                  <div className="blockquote-details justify-content-center">
                    <div className="img-blob blob1"><img src="style/images/art/t3.jpg" alt="" /></div>
                    <div className="info">
                      <h6 className="mb-0">Barclay Widerski</h6>
                      <span className="meta mb-0">Sales Manager</span>
                    </div>
                  </div>
                </blockquote>
              </div>
              
            </div>
            
          </div>
          
        </div>
        
      </div>
	 
    </div>);
};
export default Precios;