import React from "react";

const Footer = () => {

	return(
	 <footer className="dark-wrapper inverse-text">
      <div className="container inner">
        <div className="row">
          <div className="col-md-12 col-lg-12 text-center" >
            <div className="widget">
              <img src="#" srcSet="/style/images/logo.png " alt="" style={{width:300+'px'}} />
              <div className="space40"></div>
              <p>© 2020 Encuestas.io <br className="d-none d-lg-block" />Todos los derechos reservados.</p>
            </div>
           
          </div>
          
          <div className="space30 d-none d-md-block d-lg-none"></div>
          
        </div>
        
      </div>
      
    </footer>
	);
 
};
export default Footer;