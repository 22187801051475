import React, {useEffect} from "react";
import Precios from './Precios';
import Clientes from './Clientes';
import Caracteristicas from './Caracteristicas';


const Home = () => {
	useEffect( ()=>{ 

		var my_awesome_script = document.createElement('script');

		my_awesome_script.setAttribute('src','/style/js/scripts.js');

		document.head.appendChild(my_awesome_script);

	});
return(
  <div>
	<div className="wrapper bg-opacity-default">
      <div className="rev_slider_wrapper fullwidth-container dark-spinner">
         <div id="slider6" className="rev_slider fullwidthbanner light-gallery-wrapper" data-version="5.4.7">
          <ul>
            <li data-transition="fade" data-thumb="">
			
              <div  className="tp-caption font-weight-600 color-dark " 
	               data-x="['left','left','center','center']" 
	               data-y="middle" 
	               data-hoffset="['50','30','0','0']" 
	               data-voffset="['-80','-80','-160','-190']" 
	               data-fontsize="['40','40','34','32']" 
	               data-lineheight="['50','50','44','42']" 
	               data-width="['350','350','650','340']" 
	               data-textalign="['left','left','center','center']" 
	               data-whitespace="['normal','normal','normal','normal']" 
	               data-frames='[{"delay":1500,"speed":1200,"frame":"0","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]' 
	               data-responsive="" 
	               data-responsive_offset="" >Encuestas.io
	          </div>
			  
              <div className="tp-caption font-weight-400 color-dark " 
	               data-x="['left','left','center','center']" 
	               data-y="middle" 
	               data-hoffset="['50','30','0','0']" 
	               data-voffset="['30','30','-100','-90']" 
	               data-fontsize="['26','26','22','20']" 
	               data-lineheight="['36','36','32','30']" 
	               data-width="['350','350','650','340']" 
	               data-textalign="['left','left','center','center']" 
	               data-whitespace="['normal','normal','normal','normal']" 
	               data-frames='[{"delay":2000,"speed":1200,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]' 
	               data-responsive="on" 
	               data-responsive_offset="onn" >Crea encuestas de manera sencilla. 
	          </div>
			  
              <a className="tp-caption btn-play-wrapper lightbox" 
	               data-x="['left','left','center','center']" 
	               data-y="middle" 
	               data-hoffset="['50','30','0','0']" 
	               data-voffset="['125','125','-130','0']" 
	               data-width="['auto','auto','auto','auto']" 
	               data-textalign="['left','left','center','center']" 
	               data-frames='[{"delay":2500,"speed":1200,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' 
	               data-responsive="off" 
	               data-responsive_offset="off"  href="https://vimeo.com/124071444"><span className="btn-play btn-play-s"></span><span className="h6 pl-10 font-weight-600">¿Como funciona?</span>  
	          </a>
			  
              <div className="tp-caption" 
	               data-x="['right','right','center','center']" 
	               data-y="middle" 
	               data-hoffset="['60','30','0','0']" 
	               data-voffset="['30','30','60','205']" 
	               data-frames='[{"delay":500,"speed":1200,"frame":"0","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]' 
	               data-visibility="['on','on','on','on']" 
	               data-width="none" 
	               data-height="none" 
	               data-type="image" 
	               data-responsive_offset="on" ><img 
	               data-lazyload="" alt="" src="style/images/art/macbook2.png" 
	               data-ww="['740','650','550','400']" 
	               data-hh="['422','388','314','228']"  />  
	          </div>
			  
              <div className="tp-caption" 
	               data-x="['right','right','right','right']" 
	               data-y="middle" 
	               data-hoffset="['70','40','125','50']" 
	               data-voffset="['110','110','220','250']" 
	               data-frames='[{"delay":1000,"speed":1200,"frame":"0","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]' 
	               data-visibility="['on','on','on','on']" 
	               data-width="none" 
	               data-height="none" 
	               data-type="image" 
	               data-responsive_offset="on" ><img 
	               data-lazyload="" alt="" src="style/images/art/iphone4.png" 
	               data-ww="['128','120','100','74']" 
	               data-hh="['242','227','190','140']"  />  
	          </div>
			 
            </li>
          </ul>
          <div className="tp-bannertimer tp-bottom"></div>
        </div>
        
      </div>
      
    </div>
	<Clientes/>
	<Precios/>
	<Caracteristicas/>
	
	</div>);
};
export default Home;