import React from "react";

const Clientes = () => {
	
	return(
	<div className="wrapper light-wrapper">
      <div className="container inner">
        <div className="carousel owl-carousel clients" data-margin="30" data-loop="true" data-dots="false" data-autoplay="true" data-autoplay-timeout="3000" data-responsive='{"0":{"items": "2"}, "768":{"items": "4"}, "992":{"items": "5"}, "1140":{"items": "6"}}'>
          <div className="item pl-15 pr-15"><img src="/style/images/art/z1.svg" alt="" /></div>
          <div className="item pl-15 pr-15"><img src="/style/images/art/z2.svg" alt="" /></div>
          <div className="item pl-15 pr-15"><img src="/style/images/art/z3.svg" alt="" /></div>
          <div className="item pl-15 pr-15"><img src="/style/images/art/z4.svg" alt="" /></div>
          <div className="item pl-15 pr-15"><img src="/style/images/art/z5.svg" alt="" /></div>
          <div className="item pl-15 pr-15"><img src="/style/images/art/z6.svg" alt="" /></div>
          <div className="item pl-15 pr-15"><img src="/style/images/art/z7.svg" alt="" /></div>
          <div className="item pl-15 pr-15"><img src="/style/images/art/z8.svg" alt="" /></div>
        </div>
	</div>	</div>	
	);
 
};
export default Clientes;