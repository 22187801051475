import React, {  useState } from 'react';
import emailjs from 'emailjs-com';

const Contacto = () =>{
	
	const contact = {name:'',surname:'',email:'',phone:'',message:''};
	
	const [contacto,setContacto] = useState(contact);
	const [showMessage, setShowMessage] = useState(false);
	
	const handleChange = (e) => {
		
		const {name,value}=e.target;
		
		setContacto({...contacto,[name]:value});
		
	}
	
	const handleSubmit = e =>
	{
		e.preventDefault();
		
		console.log('submited form',contacto);
		
				emailjs.send('smtp_server_zoho','contacto_encuetas_io', contacto, 'user_LpVWW3oQrIHLkZNBpvItO')
				.then((response) => {
				   console.log('SUCCESS!', response.status, response.text);
				   setContacto(contact);
				   setShowMessage(true);
				}, (err) => {
				   console.log('FAILED...', err);
				});
	}
	
	
	return(
	
	<div>

			
			<div className="wrapper light-wrapper">
			  <div className="container inner">
				<h2 className="section-title">Solicita informaci&oacute;n</h2>
				<p className="lead larger">Alguna pregunta?  Utiliza el siguiente formulario para ponerte en contacto.</p>
				<div className="space40"></div>
				<div className="row">
				  <div className="col-lg-7">
				  { showMessage ? <div className="alert alert-success col-md-12 mx-auto" role="alert">Consulta enviada!!</div> : ``}
				  
					<form id="contact-form" className="fields-white" onSubmit={handleSubmit} >
					  <div className="messages"></div>
					  <div className="controls">
						<div className="form-row">
						  <div className="col-lg-12 col-xl-6">
							<div className="form-group">
							  <input id="form_name" type="text" value={contacto.name} onChange={handleChange} name="name" className="form-control" placeholder="Nombre *" required="required" data-error="First Name is required." />
							  <div className="help-block with-errors"></div>
							</div>
						  </div>
						  <div className="col-lg-12 col-xl-6">
							<div className="form-group">
							  <input id="form_lastname" type="text" value={contacto.surname} onChange={handleChange} name="surname" className="form-control" placeholder="Apellido *" required="required" data-error="Last Name is required." />
							  <div className="help-block with-errors"></div>
							</div>
						  </div>
						</div>
						<div className="form-row">
						  <div className="col-lg-12 col-xl-6">
							<div className="form-group">
							  <input id="form_email" type="email" value={contacto.email}  name="email" onChange={handleChange} className="form-control" placeholder="Email *" required="required" data-error="Valid email is required." />
							  <div className="help-block with-errors"></div>
							</div>
						  </div>
						  <div className="col-lg-12 col-xl-6">
							<div className="form-group">
							  <input id="form_phone" type="tel" value={contacto.phone}  name="phone" onChange={handleChange} className="form-control" placeholder="Tel&eacute;fono" />
							  <div className="help-block with-errors"></div>
							</div>
						  </div>
						</div>
						<div className="form-row">
						  <div className="col-md-12">
							<div className="form-group">
							  <textarea id="form_message" name="message" value={contacto.message} onChange={handleChange} className="form-control" placeholder="Mensaje *" rows="4" required="required" data-error="Message is required."></textarea>
							  <div className="help-block with-errors"></div>
							</div>
						  </div>
						  <div className="col-md-12">
							<input type="submit" className="btn btn-send" value="Enviar mensaje" />
						  </div>
						</div>
						<div className="form-row">
						  <div className="col-md-12">
							<p className="text-muted"><strong>*</strong> Estos campos son obligatorios.</p>
						  </div>
						</div>
					  </div>
					</form>
					
				  </div>
				  
				  <div className="space30 d-none d-md-block d-lg-none"></div>
				  <div className="col-lg-4 offset-lg-1">
					<div className="d-flex flex-row">
					  <div>
						<div className="icon color-default fs-34 mr-25"> <i className="icofont-location-pin"></i> </div>
					  </div>
					  <div>
						<h6 className="mb-5">Direcci&oacute;n</h6>
						<address>Av. Los Javillos Torre Tepuy Oficina 4B, Sabana Grande<br className="d-none d-md-block" />Caracas, Venezuela</address>
					  </div>
					</div>
					<div className="d-flex flex-row">
					  <div>
						<div className="icon color-default fs-34 mr-25"> <i className="icofont-telephone"></i> </div>
					  </div>
					  <div>
						<h6 className="mb-5">Tel&eacute;fonos</h6>
						<p>+58 (412) 313 8551 <br className="d-none d-md-block" />+58 (412) 825 6644</p>
					  </div>
					</div>
					<div className="d-flex flex-row">
					  <div>
						<div className="icon color-default fs-34 mr-25"> <i className="icofont-mail-box"></i> </div>
					  </div>
					  <div>
						<h6 className="mb-5">E-mails</h6>
						<p><a href="mailto:contacto@encuestas.io" className="nocolor">contacto@encuestas.io</a> <br className="d-none d-md-block" /><a href="mailto:soporte@encuestas.io" className="nocolor">soporte@encuestas.io</a></p>
					  </div>
					</div>
				  </div>
				 
				</div>
				
			  </div>
			  
    </div>
	
	 </div>
	);
};
 export default Contacto;