import React, { useState, useEffect} from "react";
import db from './firebase';
const Post = (props)  => {

const { match } = props;

    let {id} = match.params;
	
	

    const datos = { title:"",contend:"" };
	
    const [post,setPost] = useState(datos);
	
	useEffect( ()=>{
			
					db.collection("posts")
					.where("slug", "==", id)
					.get()
					.then(snap => {
						snap.forEach(doc => {
							console.log(doc.data());
							setPost(doc.data());
						});
						
						
					});
		
	});
	
	

	return(
		<div className="wrapper light-wrapper">
			<div className="container inner col-md-8 mx-auto"><h1>{post.title}</h1>
			<div className="pt-3"><p>{post.desc}</p></div>
			</div>
			
		
		</div>	
	);
 
};
export default Post;