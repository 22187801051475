import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import CaracteristicasLink from'./CaracteristicasLink';
import PreciosLink from'./PreciosLink';
import Blog from './blog';
import Contacto from'./Contacto';
import Post from'./Post';
const Main = () => (
	<Switch>
      <Route exact path="/" component={Home} />
	  <Route exact path="/caracteristicas" component={CaracteristicasLink} />
	  <Route exact path="/precios" component={PreciosLink} />
	  <Route exact path="/blog" component={Blog} />
	  <Route exact path="/contacto" component={Contacto} />
	  <Route exact path="/blog/:id" component={Post} />
    </Switch>
 );
export default Main;